import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/monitoring/rooms'
  },
  {
    path: '/monitoring/alarms',
    name: 'alarms',
    component: () => import('@/views/monitoring/AlarmsReport.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/monitoring/energy',
    name: 'energy',
    component: () => import('@/views/monitoring/EnergyReport.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/monitoring/water',
    name: 'water',
    component: () => import('@/views/monitoring/WaterReport.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/monitoring/gas',
    name: 'gas',
    component: () => import('@/views/monitoring/GasReport.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/monitoring/cold',
    name: 'cold',
    component: () => import('@/views/monitoring/ColdReport.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/monitoring/hot',
    name: 'hot',
    component: () => import('@/views/monitoring/HotReport.vue'),
    meta: { requiresAuth: true, requiredRole: 'Hotelaria' }
  },
  {
    path: '/monitoring/power',
    name: 'power',
    component: () => import('@/views/monitoring/PowerReport.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/monitoring/rooms',
    name: 'rooms',
    component: () => import('@/views/monitoring/RoomsReport.vue'),
    meta: { requiresAuth: true, requiredRole: 'Hotelaria' }
  },
  {
    path: '/control/hvac',
    name: 'hvac',
    component: () => import('@/views/controls/HvacControl.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/control/lights',
    name: 'lights',
    component: () => import('@/views/controls/LightsControl.vue'),
    meta: { requiresAuth: true, requiredRole: 'Hotelaria' }
  },
  {
    path: '/control/pumps',
    name: 'pumps',
    component: () => import('@/views/controls/PumpsControl.vue'),
    meta: { requiresAuth: true, requiredRole: 'Hotelaria' }
  },
  {
    path: '/reports/stock',
    name: 'stock',
    component: () => import('@/views/reports/StockReport.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reports/requests',
    name: 'requests',
    component: () => import('@/views/reports/RequestsReport.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
    beforeEnter: (to, from, next) => {
      const isAuthenticated = !!localStorage.getItem('accessToken');

      if (isAuthenticated) {
        next('/');
      } else {
        next();
      }
    }
  },
  {
    path: '*',
    redirect: '/'
  },
]

const router = new VueRouter({
  mode:'history',
  routes
})

router.beforeEach((to, from, next) => {
  const user_role = store.state.user_role;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('accessToken')) {
      next({ path: '/login' });
    } else {
      if (to.meta.requiredRole && user_role !== to.meta.requiredRole) {
        return next('/unauthorized');
      }

      next();
    }
  } else {
    next();
  }
});

export default router
