import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAuthenticated: !!localStorage.getItem('accessToken'),
    user_mail: localStorage.getItem('user_mail') || null,
    user_name: localStorage.getItem('user_name') || null,
    user_role: localStorage.getItem('user_role') || null,
    system: localStorage.getItem('system') || 'monitoring',
  },
  getters: {
  },
  mutations: {
    setAuthentication(state, {status, user_mail, user_name, user_role}) {
      state.isAuthenticated = status;
      state.user_mail = user_mail;
      state.user_name = user_name;
      state.user_role = user_role;
    },
    setSystem(state, system) {
      state.system = system;
      localStorage.setItem('system', system);
    }
  },
  actions: {
    updateSystem({ commit }, system) {
      commit('setSystem', system);
    },
  },
  modules: {
  }
})
