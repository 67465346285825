<template>
  <v-app>
    <v-main :class="{ 'hotelaria': isFromHotels, 'hospitais': isFromHospitals, 'monitoring': isMonitoring, 'control': isControl, 'reports': isReports }">
      <DesktopSelector v-if="isAuthenticated" :systems="systems" />
      <MobileSelector v-if="isAuthenticated" @drawer="receiveDrawer" :systems="systems" />
      <MainMenu v-if="isAuthenticated" :drawer="drawer" :items="items" />
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

  import DesktopSelector from '@/components/default/DesktopSelector.vue'
  import MainMenu from '@/components/default/MainMenu.vue'
  import MobileSelector from '@/components/default/MobileSelector.vue'

//tremors: car-brake-alert

  export default {
    name: 'App',
    components: {
      DesktopSelector,
      MainMenu,
      MobileSelector
    },
    computed: {
      isAuthenticated() {
        return this.$store.state.isAuthenticated;
      },
      isFromHotels() {
        return (this.$store.state.user_role == 'Hotelaria');
      },
      isFromHospitals() {
        return (this.$store.state.user_role == 'Hospitais');
      },
      isMonitoring() {
        return (this.$store.state.system == 'monitoring');
      },
      isControl() {
        return (this.$store.state.system == 'control');
      },
      isReports() {
        return (this.$store.state.system == 'reports');
      },
    },
    data: () => ({
      items: [
        // {
        //   icon: 'mdi-alarm-light-outline',
        //   text: 'Gestão de alarmes',
        //   link: '/monitoring/alarms',
        //   class: "alarms"
        // },
        {
          icon: 'mdi-bell-outline',
          text: 'Gestão de quartos',
          link: '/monitoring/rooms',
          class: "rooms monitoring"
        },
        {
          icon: 'mdi-power-plug-outline',
          text: 'Gestão de energia',
          link: '/monitoring/energy',
          class: "energy monitoring"
        },
        {
          icon: 'mdi-water-outline',
          text: 'Gestão de água',
          link: '/monitoring/water',
          class: "water monitoring"
        },
        {
          icon: 'mdi-propane-tank-outline',
          text: 'Gestão de gás',
          link: '/monitoring/gas',
          class: "gas monitoring"
        },
        {
          icon: 'mdi-meter-electric-outline',
          text: 'Gestão de geradores',
          link: '/monitoring/power',
          class: "power monitoring"
        },
        {
          icon: 'mdi-heating-coil',
          text: 'Gestão de aquecimento',
          link: '/monitoring/hot',
          class: "hot monitoring"
        },
        {
          icon: 'mdi-fridge-industrial-outline',
          text: 'Gestão de refrigeradores',
          link: '/monitoring/cold',
          class: "cold monitoring"
        },
        {
          icon: 'mdi-lightbulb-outline',
          text: 'Gestão de iluminação',
          link: '/control/lights',
          class: "lights control"
        },
        {
          icon: 'mdi-engine-outline',
          text: 'Gestão de bombas',
          link: '/control/pumps',
          class: "pumps control"
        },
        {
          icon: 'mdi-snowflake-check',
          text: 'Gestão do ar-condicionado',
          link: '/control/hvac',
          class: "hvac control"
        },
        {
          icon: 'mdi-wrench-outline',
          text: 'Gestão de chamados',
          link: '/reports/requests',
          class: "requests reports"
        },
        {
          icon: 'mdi-toolbox-outline',
          text: 'Gestão de estoque',
          link: '/reports/stock',
          class: "stock reports"
        },
      ],
      systems: [
        {
          selected:false,
          icon: 'mdi-file-chart-outline',
          system: 'reports',
          to: '/reports/requests',
          name: 'Relatórios'
        },
        {
          selected:false,
          icon: 'mdi-signal-variant',
          system: 'monitoring',
          to: '/monitoring/rooms',
          name: 'Monitoramento'
        },
        {
          selected:false,
          icon: 'mdi-light-switch-off',
          system:'control',
          to: '/control/lights',
          name: 'Controle'
        }
      ],
      drawer:true
    }),
    methods: {
      receiveDrawer() {
        this.drawer = !this.drawer;
      }
    },
    mounted() {
      window.onload = () => {
        var j = this.systems.findIndex(e => (e.system == window.location.pathname.split("/")[1]));

        if(j < 0) {
          j = 0;
        }

        this.systems[j].selected = true;
      }
    }
  }
</script>

<style lang="scss">
  @import '@/styles/variables.scss';
  @import '@/styles/default.scss';
  @import '@/styles/desktop-selector.scss';
  @import '@/styles/mobile-selector.scss';
  @import '@/styles/main-menu.scss';
  @import '@/styles/main-canva.scss';

  @import '@/styles/linechart-current.scss';
  @import '@/styles/historic-heatmap.scss';
  @import '@/styles/historic-barchart.scss';
  @import '@/styles/metrics.scss';
  @import '@/styles/thermometer.scss';
  @import '@/styles/switch.scss';
  @import '@/styles/room-card.scss';
  @import '@/styles/loading.scss';
  
  @import '@/styles/lights-control.scss';
  @import '@/styles/pumps-control.scss';
  @import '@/styles/hvac-control.scss';

  @import '@/styles/stock-table.scss';
  @import '@/styles/requests-pie-chart.scss';
  @import '@/styles/requests-ranking.scss';
  @import '@/styles/requests-table.scss';

  html {
    scroll-behavior: smooth;
  }

  .report-container {
    background-color:#FFF;
    padding:20px;
    box-shadow:2px 2px 4px rgba(0,0,0,.1);
    border-radius:15px;
    position:relative;

    h2 {
      font-size:18px;
      text-transform: uppercase;
    }
  }
</style>